import React from 'react';
import { graphql, PageProps } from 'gatsby';

import PageHeader from '../../components/PageHeader';
import PaginationV2 from '../../components/PaginationV2';
import GuideBlocks from '../../components/GuideBlocks';
import { getArticles, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE } from '../../constants';

const ARCHIVE_NAME = 'Browse All Articles';

interface IAllArticlesTemplateProps {
  articles: Queries.WpPostConnection;
}

interface IAllArticlesContext {
  limit: number;
  page: number;
  slug: string;
  totalArchivePages: number;
}

const AllArticlesArchiveTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IAllArticlesTemplateProps, IAllArticlesContext>) => {
  const currentPageIndex = pageContext.page - 1 || 0;
  const basePath = pageContext.slug || '/';
  const totalNumberOfPages = pageContext.totalArchivePages || 0;
  const pageNumber = pageContext.page;
  const articlesPerPage = pageContext.limit;
  const mutablePosts: Queries.WpPost[] = [...data.articles.nodes];
  const articleExcerpts = getArticles(mutablePosts);
  const title = pageNumber === 1 ? ARCHIVE_NAME : ARCHIVE_NAME + ' - Page ' + pageNumber;

  return (
    <HTPageWrapper category={"Article, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE} title={title} location={location}>
      <PageHeader
        showGraphic
        title={title}
        breadCrumbs={[
          {
            text: 'Home',
            link: '/',
          },
        ]}
      />
      <GuideBlocks articles={articleExcerpts} variant="columns" />
      {totalNumberOfPages > 0 && (
        <PaginationV2
          currentPageIndex={currentPageIndex}
          basePath={basePath}
          pageSize={articlesPerPage}
          numberOfPages={totalNumberOfPages}
        />
      )}
    </HTPageWrapper>
  );
};

export default AllArticlesArchiveTemplate;

export const Head = ({
  data,
  pageContext,
}: PageProps<IAllArticlesTemplateProps, IAllArticlesContext>) => {
  const articles = data.articles.nodes || [];
  const firstPost = articles[0];
  const pageNumber = pageContext.page;
  const pageSuffix = pageNumber === 1 ? '' : `page/${pageNumber}/`;
  const pathname = pageContext.slug + pageSuffix;
  const pageTitle = pageNumber === 1 ? ARCHIVE_NAME : ARCHIVE_NAME + ' Page ' + pageNumber;
  const socialTitle =
    pageNumber === 1
      ? 'All Articles | Upgraded Points'
      : 'All Articles Archives - Page ' + pageNumber + ' | Upgraded Points';
  let ogDescription = 'Browse all of our credit card and travel articles.';
  if (pageNumber > 1) {
    ogDescription += ' Page ' + pageNumber + '.';
  }
  const seo = getDefaultSEO(
    pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    true,
    null,
    ogDescription,
  );
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return <Seo title={pageTitle} wpSeo={seo} featuredImageData={featuredImageData} />;
};

export const query = graphql`
  query AllArticlesArchivePosts($postIds: [String!]) {
    articles: allWpPost(filter: { id: { in: $postIds } }) {
      nodes {
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
